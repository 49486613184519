// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-154-service-js": () => import("./../../../src/pages/154-service.js" /* webpackChunkName: "component---src-pages-154-service-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-526-service-js": () => import("./../../../src/pages/526-service.js" /* webpackChunkName: "component---src-pages-526-service-js" */),
  "component---src-pages-574-service-js": () => import("./../../../src/pages/574-service.js" /* webpackChunkName: "component---src-pages-574-service-js" */),
  "component---src-pages-575-service-js": () => import("./../../../src/pages/575-service.js" /* webpackChunkName: "component---src-pages-575-service-js" */),
  "component---src-pages-bg-1-service-js": () => import("./../../../src/pages/bg1-service.js" /* webpackChunkName: "component---src-pages-bg-1-service-js" */),
  "component---src-pages-bg-2-brigshaw-garforth-js": () => import("./../../../src/pages/bg2-brigshaw-garforth.js" /* webpackChunkName: "component---src-pages-bg-2-brigshaw-garforth-js" */),
  "component---src-pages-bg-2-service-js": () => import("./../../../src/pages/bg2-service.js" /* webpackChunkName: "component---src-pages-bg-2-service-js" */),
  "component---src-pages-bg-3-service-js": () => import("./../../../src/pages/bg3-service.js" /* webpackChunkName: "component---src-pages-bg-3-service-js" */),
  "component---src-pages-br-11-service-js": () => import("./../../../src/pages/br11-service.js" /* webpackChunkName: "component---src-pages-br-11-service-js" */),
  "component---src-pages-br-12-service-js": () => import("./../../../src/pages/br12-service.js" /* webpackChunkName: "component---src-pages-br-12-service-js" */),
  "component---src-pages-br-13-service-js": () => import("./../../../src/pages/br13-service.js" /* webpackChunkName: "component---src-pages-br-13-service-js" */),
  "component---src-pages-br-2-service-js": () => import("./../../../src/pages/br2-service.js" /* webpackChunkName: "component---src-pages-br-2-service-js" */),
  "component---src-pages-by-25-service-js": () => import("./../../../src/pages/by25-service.js" /* webpackChunkName: "component---src-pages-by-25-service-js" */),
  "component---src-pages-cc-1-service-js": () => import("./../../../src/pages/cc1-service.js" /* webpackChunkName: "component---src-pages-cc-1-service-js" */),
  "component---src-pages-cc-2-service-js": () => import("./../../../src/pages/cc2-service.js" /* webpackChunkName: "component---src-pages-cc-2-service-js" */),
  "component---src-pages-coach-hire-bradford-js": () => import("./../../../src/pages/coach-hire-bradford.js" /* webpackChunkName: "component---src-pages-coach-hire-bradford-js" */),
  "component---src-pages-coach-hire-halifax-js": () => import("./../../../src/pages/coach-hire-halifax.js" /* webpackChunkName: "component---src-pages-coach-hire-halifax-js" */),
  "component---src-pages-coach-hire-harrogate-js": () => import("./../../../src/pages/coach-hire-harrogate.js" /* webpackChunkName: "component---src-pages-coach-hire-harrogate-js" */),
  "component---src-pages-coach-hire-huddersfield-js": () => import("./../../../src/pages/coach-hire-huddersfield.js" /* webpackChunkName: "component---src-pages-coach-hire-huddersfield-js" */),
  "component---src-pages-coach-hire-services-airport-transfers-js": () => import("./../../../src/pages/coach-hire-services/airport-transfers.js" /* webpackChunkName: "component---src-pages-coach-hire-services-airport-transfers-js" */),
  "component---src-pages-coach-hire-services-club-pub-trips-js": () => import("./../../../src/pages/coach-hire-services/club-pub-trips.js" /* webpackChunkName: "component---src-pages-coach-hire-services-club-pub-trips-js" */),
  "component---src-pages-coach-hire-services-coast-country-day-trips-js": () => import("./../../../src/pages/coach-hire-services/coast-country-day-trips.js" /* webpackChunkName: "component---src-pages-coach-hire-services-coast-country-day-trips-js" */),
  "component---src-pages-coach-hire-services-college-university-trips-js": () => import("./../../../src/pages/coach-hire-services/college-university-trips.js" /* webpackChunkName: "component---src-pages-coach-hire-services-college-university-trips-js" */),
  "component---src-pages-coach-hire-services-community-groups-js": () => import("./../../../src/pages/coach-hire-services/community-groups.js" /* webpackChunkName: "component---src-pages-coach-hire-services-community-groups-js" */),
  "component---src-pages-coach-hire-services-contract-coach-hire-js": () => import("./../../../src/pages/coach-hire-services/contract-coach-hire.js" /* webpackChunkName: "component---src-pages-coach-hire-services-contract-coach-hire-js" */),
  "component---src-pages-coach-hire-services-corporate-events-coach-hire-js": () => import("./../../../src/pages/coach-hire-services/corporate-events-coach-hire.js" /* webpackChunkName: "component---src-pages-coach-hire-services-corporate-events-coach-hire-js" */),
  "component---src-pages-coach-hire-services-day-at-the-races-js": () => import("./../../../src/pages/coach-hire-services/day-at-the-races.js" /* webpackChunkName: "component---src-pages-coach-hire-services-day-at-the-races-js" */),
  "component---src-pages-coach-hire-services-funerals-js": () => import("./../../../src/pages/coach-hire-services/funerals.js" /* webpackChunkName: "component---src-pages-coach-hire-services-funerals-js" */),
  "component---src-pages-coach-hire-services-group-tours-js": () => import("./../../../src/pages/coach-hire-services/group-tours.js" /* webpackChunkName: "component---src-pages-coach-hire-services-group-tours-js" */),
  "component---src-pages-coach-hire-services-js": () => import("./../../../src/pages/coach-hire-services.js" /* webpackChunkName: "component---src-pages-coach-hire-services-js" */),
  "component---src-pages-coach-hire-services-school-trips-js": () => import("./../../../src/pages/coach-hire-services/school-trips.js" /* webpackChunkName: "component---src-pages-coach-hire-services-school-trips-js" */),
  "component---src-pages-coach-hire-services-sporting-events-js": () => import("./../../../src/pages/coach-hire-services/sporting-events.js" /* webpackChunkName: "component---src-pages-coach-hire-services-sporting-events-js" */),
  "component---src-pages-coach-hire-services-theatre-concerts-cultural-events-js": () => import("./../../../src/pages/coach-hire-services/theatre-concerts-cultural-events.js" /* webpackChunkName: "component---src-pages-coach-hire-services-theatre-concerts-cultural-events-js" */),
  "component---src-pages-coach-hire-services-theme-parks-js": () => import("./../../../src/pages/coach-hire-services/theme-parks.js" /* webpackChunkName: "component---src-pages-coach-hire-services-theme-parks-js" */),
  "component---src-pages-coach-hire-services-weddings-engagements-parties-nights-out-js": () => import("./../../../src/pages/coach-hire-services/weddings-engagements-parties-nights-out.js" /* webpackChunkName: "component---src-pages-coach-hire-services-weddings-engagements-parties-nights-out-js" */),
  "component---src-pages-coach-hire-services-wheelchair-disabled-access-coach-hire-js": () => import("./../../../src/pages/coach-hire-services/wheelchair-disabled-access-coach-hire.js" /* webpackChunkName: "component---src-pages-coach-hire-services-wheelchair-disabled-access-coach-hire-js" */),
  "component---src-pages-coach-hire-wakefield-js": () => import("./../../../src/pages/coach-hire-wakefield.js" /* webpackChunkName: "component---src-pages-coach-hire-wakefield-js" */),
  "component---src-pages-coach-hire-york-js": () => import("./../../../src/pages/coach-hire-york.js" /* webpackChunkName: "component---src-pages-coach-hire-york-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fa-1-service-js": () => import("./../../../src/pages/fa1-service.js" /* webpackChunkName: "component---src-pages-fa-1-service-js" */),
  "component---src-pages-fleet-32-35-seat-midi-bus-js": () => import("./../../../src/pages/fleet/32-35-seat-midi-bus.js" /* webpackChunkName: "component---src-pages-fleet-32-35-seat-midi-bus-js" */),
  "component---src-pages-fleet-49-53-seat-coach-js": () => import("./../../../src/pages/fleet/49-53-seat-coach.js" /* webpackChunkName: "component---src-pages-fleet-49-53-seat-coach-js" */),
  "component---src-pages-fleet-57-seat-school-bus-js": () => import("./../../../src/pages/fleet/57-seat-school-bus.js" /* webpackChunkName: "component---src-pages-fleet-57-seat-school-bus-js" */),
  "component---src-pages-fleet-70-seat-coach-js": () => import("./../../../src/pages/fleet/70-seat-coach.js" /* webpackChunkName: "component---src-pages-fleet-70-seat-coach-js" */),
  "component---src-pages-fleet-disabled-access-coach-js": () => import("./../../../src/pages/fleet/disabled-access-coach.js" /* webpackChunkName: "component---src-pages-fleet-disabled-access-coach-js" */),
  "component---src-pages-fleet-double-decker-js": () => import("./../../../src/pages/fleet/double-decker.js" /* webpackChunkName: "component---src-pages-fleet-double-decker-js" */),
  "component---src-pages-fleet-js": () => import("./../../../src/pages/fleet.js" /* webpackChunkName: "component---src-pages-fleet-js" */),
  "component---src-pages-fleet-minibus-hire-js": () => import("./../../../src/pages/fleet/minibus-hire.js" /* webpackChunkName: "component---src-pages-fleet-minibus-hire-js" */),
  "component---src-pages-g-1-service-js": () => import("./../../../src/pages/g1-service.js" /* webpackChunkName: "component---src-pages-g-1-service-js" */),
  "component---src-pages-g-2-service-js": () => import("./../../../src/pages/g2-service.js" /* webpackChunkName: "component---src-pages-g-2-service-js" */),
  "component---src-pages-g-3-service-js": () => import("./../../../src/pages/g3-service.js" /* webpackChunkName: "component---src-pages-g-3-service-js" */),
  "component---src-pages-g-4-service-js": () => import("./../../../src/pages/g4-service.js" /* webpackChunkName: "component---src-pages-g-4-service-js" */),
  "component---src-pages-heckmondwike-grammar-services-js": () => import("./../../../src/pages/heckmondwike-grammar-services.js" /* webpackChunkName: "component---src-pages-heckmondwike-grammar-services-js" */),
  "component---src-pages-hgs-1-service-js": () => import("./../../../src/pages/hgs1-service.js" /* webpackChunkName: "component---src-pages-hgs-1-service-js" */),
  "component---src-pages-hgs-11-service-js": () => import("./../../../src/pages/hgs11-service.js" /* webpackChunkName: "component---src-pages-hgs-11-service-js" */),
  "component---src-pages-hgs-12-service-js": () => import("./../../../src/pages/hgs12-service.js" /* webpackChunkName: "component---src-pages-hgs-12-service-js" */),
  "component---src-pages-hgs-13-service-js": () => import("./../../../src/pages/hgs13-service.js" /* webpackChunkName: "component---src-pages-hgs-13-service-js" */),
  "component---src-pages-hgs-2-service-js": () => import("./../../../src/pages/hgs2-service.js" /* webpackChunkName: "component---src-pages-hgs-2-service-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-l-73-service-js": () => import("./../../../src/pages/l73-service.js" /* webpackChunkName: "component---src-pages-l-73-service-js" */),
  "component---src-pages-lc-1-service-js": () => import("./../../../src/pages/lc1-service.js" /* webpackChunkName: "component---src-pages-lc-1-service-js" */),
  "component---src-pages-lc-2-service-js": () => import("./../../../src/pages/lc2-service.js" /* webpackChunkName: "component---src-pages-lc-2-service-js" */),
  "component---src-pages-lc-3-service-js": () => import("./../../../src/pages/lc3-service.js" /* webpackChunkName: "component---src-pages-lc-3-service-js" */),
  "component---src-pages-news-commitment-to-safety-js": () => import("./../../../src/pages/news/commitment-to-safety.js" /* webpackChunkName: "component---src-pages-news-commitment-to-safety-js" */),
  "component---src-pages-news-digital-transformation-js": () => import("./../../../src/pages/news/digital-transformation.js" /* webpackChunkName: "component---src-pages-news-digital-transformation-js" */),
  "component---src-pages-news-emmerdale-tour-shuttle-bus-js": () => import("./../../../src/pages/news/emmerdale-tour-shuttle-bus.js" /* webpackChunkName: "component---src-pages-news-emmerdale-tour-shuttle-bus-js" */),
  "component---src-pages-news-heckmondwike-grammar-bus-pass-js": () => import("./../../../src/pages/news/heckmondwike-grammar-bus-pass.js" /* webpackChunkName: "component---src-pages-news-heckmondwike-grammar-bus-pass-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-leeds-uni-transport-contract-extended-js": () => import("./../../../src/pages/news/leeds-uni-transport-contract-extended.js" /* webpackChunkName: "component---src-pages-news-leeds-uni-transport-contract-extended-js" */),
  "component---src-pages-news-new-website-launch-js": () => import("./../../../src/pages/news/new-website-launch.js" /* webpackChunkName: "component---src-pages-news-new-website-launch-js" */),
  "component---src-pages-news-operator-licence-increase-to-60-vehicles-js": () => import("./../../../src/pages/news/operator-licence-increase-to-60-vehicles.js" /* webpackChunkName: "component---src-pages-news-operator-licence-increase-to-60-vehicles-js" */),
  "component---src-pages-news-tetleys-acquire-ridings-coaches-js": () => import("./../../../src/pages/news/tetleys-acquire-ridings-coaches.js" /* webpackChunkName: "component---src-pages-news-tetleys-acquire-ridings-coaches-js" */),
  "component---src-pages-news-tetleys-add-wheelchair-accessible-coach-js": () => import("./../../../src/pages/news/tetleys-add-wheelchair-accessible-coach.js" /* webpackChunkName: "component---src-pages-news-tetleys-add-wheelchair-accessible-coach-js" */),
  "component---src-pages-news-tetleys-advert-on-made-in-leeds-js": () => import("./../../../src/pages/news/tetleys-advert-on-made-in-leeds.js" /* webpackChunkName: "component---src-pages-news-tetleys-advert-on-made-in-leeds-js" */),
  "component---src-pages-news-tetleys-become-leeds-met-uni-transport-supplier-js": () => import("./../../../src/pages/news/tetleys-become-leeds-met-uni-transport-supplier.js" /* webpackChunkName: "component---src-pages-news-tetleys-become-leeds-met-uni-transport-supplier-js" */),
  "component---src-pages-news-tetleys-become-leeds-uni-transport-supplier-js": () => import("./../../../src/pages/news/tetleys-become-leeds-uni-transport-supplier.js" /* webpackChunkName: "component---src-pages-news-tetleys-become-leeds-uni-transport-supplier-js" */),
  "component---src-pages-news-tetleys-coaches-achieves-living-wage-employer-accreditation-js": () => import("./../../../src/pages/news/tetleys-coaches-achieves-living-wage-employer-accreditation.js" /* webpackChunkName: "component---src-pages-news-tetleys-coaches-achieves-living-wage-employer-accreditation-js" */),
  "component---src-pages-news-tetleys-join-metro-mybus-school-transport-scheme-js": () => import("./../../../src/pages/news/tetleys-join-metro-mybus-school-transport-scheme.js" /* webpackChunkName: "component---src-pages-news-tetleys-join-metro-mybus-school-transport-scheme-js" */),
  "component---src-pages-news-tetleys-partner-with-shuttleid-js": () => import("./../../../src/pages/news/tetleys-partner-with-shuttleid.js" /* webpackChunkName: "component---src-pages-news-tetleys-partner-with-shuttleid-js" */),
  "component---src-pages-news-tetleys-provide-transport-china-olympic-team-js": () => import("./../../../src/pages/news/tetleys-provide-transport-china-olympic-team.js" /* webpackChunkName: "component---src-pages-news-tetleys-provide-transport-china-olympic-team-js" */),
  "component---src-pages-news-tetleys-supply-coaches-for-bbc-spoty-js": () => import("./../../../src/pages/news/tetleys-supply-coaches-for-bbc-spoty.js" /* webpackChunkName: "component---src-pages-news-tetleys-supply-coaches-for-bbc-spoty-js" */),
  "component---src-pages-news-tetleys-win-capita-contract-js": () => import("./../../../src/pages/news/tetleys-win-capita-contract.js" /* webpackChunkName: "component---src-pages-news-tetleys-win-capita-contract-js" */),
  "component---src-pages-news-tetleys-win-mid-yorkshire-nhs-trust-contract-js": () => import("./../../../src/pages/news/tetleys-win-mid-yorkshire-nhs-trust-contract.js" /* webpackChunkName: "component---src-pages-news-tetleys-win-mid-yorkshire-nhs-trust-contract-js" */),
  "component---src-pages-news-wakefield-free-bus-relaunch-js": () => import("./../../../src/pages/news/wakefield-free-bus-relaunch.js" /* webpackChunkName: "component---src-pages-news-wakefield-free-bus-relaunch-js" */),
  "component---src-pages-p-53-service-js": () => import("./../../../src/pages/p53-service.js" /* webpackChunkName: "component---src-pages-p-53-service-js" */),
  "component---src-pages-p-54-service-js": () => import("./../../../src/pages/p54-service.js" /* webpackChunkName: "component---src-pages-p-54-service-js" */),
  "component---src-pages-p-89-service-js": () => import("./../../../src/pages/p89-service.js" /* webpackChunkName: "component---src-pages-p-89-service-js" */),
  "component---src-pages-policies-documents-js": () => import("./../../../src/pages/policies-documents.js" /* webpackChunkName: "component---src-pages-policies-documents-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-r-10-service-js": () => import("./../../../src/pages/r10-service.js" /* webpackChunkName: "component---src-pages-r-10-service-js" */),
  "component---src-pages-r-5-service-js": () => import("./../../../src/pages/r5-service.js" /* webpackChunkName: "component---src-pages-r-5-service-js" */),
  "component---src-pages-rossett-late-bus-service-js": () => import("./../../../src/pages/rossett-late-bus-service.js" /* webpackChunkName: "component---src-pages-rossett-late-bus-service-js" */),
  "component---src-pages-s-20-service-js": () => import("./../../../src/pages/s20-service.js" /* webpackChunkName: "component---src-pages-s-20-service-js" */),
  "component---src-pages-s-62-service-js": () => import("./../../../src/pages/s62-service.js" /* webpackChunkName: "component---src-pages-s-62-service-js" */),
  "component---src-pages-school-services-js": () => import("./../../../src/pages/school-services.js" /* webpackChunkName: "component---src-pages-school-services-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-staff-shuttle-capita-arlington-business-centre-js": () => import("./../../../src/pages/staff-shuttle-capita-arlington-business-centre.js" /* webpackChunkName: "component---src-pages-staff-shuttle-capita-arlington-business-centre-js" */),
  "component---src-pages-staff-shuttle-forza-foods-js": () => import("./../../../src/pages/staff-shuttle-forza-foods.js" /* webpackChunkName: "component---src-pages-staff-shuttle-forza-foods-js" */),
  "component---src-pages-tn-1-service-js": () => import("./../../../src/pages/tn1-service.js" /* webpackChunkName: "component---src-pages-tn-1-service-js" */)
}

